
















































































































import {Component, Mixins} from 'vue-property-decorator';
import gql from 'graphql-tag';
import {WorkspaceInvite} from '@/typescript/types';
import {MeQuery, RegisterMutation} from '@/graphql/auth';
import {ValidationErrorParserMixin} from '@/mixins/ValidationErrorParserMixin';
import LoginModal from '@/components/auth/login/LoginModal.vue';
import {WorkspaceInviteFragment} from '@/graphql/workspace_invite';
import PusherService from '@/PusherService';

@Component({
  apollo: {
    workspaceInvite: {
      query: gql`
                query getWorkspaceInvite($pid: ID!) {
                    workspaceInvite(pid: $pid) {
                        ...WorkspaceInviteDetailed
                    }
                }
                ${WorkspaceInviteFragment}
            `,
      variables() {
        return {
          pid: this.$route.params.pid,
        };
      },
      error(_error) {
       
        this.$router.push({name: 'dashboard'})
      },
    },
  },
})
export default class InvitationView extends Mixins(ValidationErrorParserMixin) {
  clicked = false;
  hasError = false;
  first_name: string | null = null;
  last_name: string | null = null;
  password: string | null = null;
  workspaceInvite: WorkspaceInvite | null = null;
  currentIpAddress: string | null = null;

  get firstBoardPid() {
    if (this.workspaceInvite) {
      return this.workspaceInvite.boards![0].pid;
    }

    return null;
  }

  get isPasswordValid() {
    const password = this.password ?? '';
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
    
  }

  get showPasswordError() {
    if(!this.isPasswordValid && this.password) {
      return true;
    } else {
      return false;
    }
  }

  get isAcceptButtonDisabled(){
    if(!!this.first_name && !!this.last_name && this.isPasswordValid ){
      return false;
    }
    return true;
  }

  accept() {
    this.clicked = true;
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation AcceptWorkspaceInviteMutation($id: ID!) {
                        acceptWorkspaceInvite(id: $id) {
                            id
                        }
                    }
                `,
        variables: {
          id: this.workspaceInvite!.id,
        },
        refetchQueries: [{query: MeQuery}],
      })
      .then(() => {
        if(this.workspaceInvite) {
          this.$router.push({
            name: 'dashboard-layout', params: {
              workspace_pid: this.workspaceInvite?.workspace.pid
            }
          });
        }
      })
      .catch(() => {
        console.error('Whoops');
        this.hasError = true;
      })
      .finally(() => {
        this.clicked = false;
      });
  }

  register() {
    this.clicked = true;
    this.$apollo
      .mutate({
        mutation: RegisterMutation,
        variables: {
          email: this.workspaceInvite!.invitee_email,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
          password_confirmation: this.password,
          ipAddress: this.currentIpAddress
        },
      })
      .then(({data: {register}}) => {
        this.$store.commit('logged_in', register.access_token);
        PusherService.pusherAuth();
        this.accept();
      })
      .catch(errorResponse => {
        this.parseValidationError(errorResponse);
        this.clicked = false;
      });
  }

  reject() {
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation RejectWorkspaceInviteMutation($id: ID!) {
                        rejectWorkspaceInvite(id: $id) {
                            id
                        }
                    }
                `,
        variables: {
          id: this.workspaceInvite!.id,
        },
        refetchQueries: [{query: MeQuery}],
      })
      .then(() => {
        this.$router.push({name: 'dashboard'});
      });
  }

  showLoginModal() {
    this.$buefy.modal.open({
      component: LoginModal,
      props: {
        email: this.workspaceInvite?.invitee_email,
      },
      parent: this,
      width: 450,
    });
  }

  mounted() {
    //@ts-ignore
    this.$ipaddress.getIpAddress().then((res: string)=>{
      this.currentIpAddress = res;
    });
  }
}
